$trans: all 0.6s ease;

/****  Colors ****/
$brand: #5D9562;
$brand-dark: #436A46;
$brand-light: rgba(93, 149, 98, 0.16);
// $secondary: #9CB5C9;
$secondary: rgb(170, 187, 197);
$secondary-dark: #8194A6;
$secondary-light: rgba(156, 181, 201, 0.16);
$light: #EBEAEF;
$text: #131516;
$grey: #4D5860;


/****  Fonts ****/
$ff:  'Proxima Nova Regular';
$ff-semibold:  'Proxima Nova Semibold';
$ff-bold:  'Proxima Nova Bold';
$ff-light:  'Proxima Nova Light';
$sec:  'NHaasGroteskDSPro-55Rg';
$sec-it:  'NHaasGroteskDSStd-56It';
$sec-black:  'NHaasGroteskDSPro-95Blk';
$sec-black-it:  'NHaasGroteskDSPro-96BlkIt';
$sec-med:  'NHaasGroteskDSPro-66MdIt';
$sec-med-norm:  'NHaasGroteskDSPro-65Md';
$sec-bold:  'NHaasGroteskDSPro-75Bd';
$sec-bold-it:  'NHaasGroteskDSStd-76BdIt';
