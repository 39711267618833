@import 'system/variables';
@import 'system/mixin';
.slick-next:before,
.slick-prev:before {
  color: $secondary-dark;
  // content: '→';
  @include vw($font-size: 100);
}
.slick-next {
  transform: translate(110%, -50%);
  right: 0;
  width: auto;
}
.slick-prev {
  transform: translate(-110%, -50%);
  left: 0;
  width: auto;
}
.arrow {
  display: none;
  @include respond-to(xs) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 35px;
    height: 35px;
    // border: 2px solid $grey;
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0.6;
    top: 50%;
    z-index: 9999;
    border-radius: 50%;
    font-size: 20px;
    transform: translateY(-50%);
    &-next {
      right: 0;
    }
  }
}
.turn-page {
  background: #fff;
  background-repeat: no-repeat;
}
.hamburger {
  width: 60px;
  height: 60px;
  background: $brand;
  position: fixed;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  z-index: 999;
  @include vw(
    $top: 20,
    $right: 30,
    $width: 60,
    $height: 60,
    $padding: 15,
    $box-shadow: 0 0 14 -1 $grey
  );
  display: none;
  @include respond-to(xs) {
    right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .icon-open {
    display: block;
    width: 44%;
  }
  .icon-close {
    display: none;
    width: 37%;
  }
  &.is-open {
    background: $text;
    .icon-open {
      display: none;
    }
    .icon-close {
      display: block;
    }
  }
}
.page-01 {
  @include respond-to(xs) {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
    overflow: hidden;
    transform: translateX(100%);
    transition: all 0.6s ease;
  }
  &.is-open {
    transform: translateX(0);
  }
  .page__content {
    @include respond-to(xs) {
      padding: 0;
      overflow-y: scroll;
      height: 100vh;
    }
  }
}
.content-width {
  @include vw($width: 593, $padding: 0 30, $margin: 0 auto);
  max-width: 100%;
  @include respond-to(xs) {
    // padding: 0 3vh;
  }
}
.title {
  @include vw($font-size: 54, $margin-bottom: 30);
  color: $brand;
  font-family: $sec-black;
  line-height: 1.05;
  @include respond-to(xs) {
    // font-size: 32px;
    // margin-bottom: 18px;
  }
  span {
    line-height: 1.05;
  }
  &-note {
    font-family: $sec-bold;
    display: block;
    @include vw(
      $font-size: 14,
      $margin-left: 50,
      $margin-top: -30,
      $margin-bottom: 13
    );
    @include respond-to(xs) {
      //   font-size: 10px;
      // margin-bottom: 5px;
      // margin-top: -18px;
      // margin-left: 28px;
    }
  }
  &--img {
    display: flex;
    align-items: center;
    @include vw($margin-bottom: 10);
    @include respond-to(xs) {
      // margin-bottom: 2vh;
      // margin-bottom: 10px;
    }
  }
  &__img {
    max-width: 100%;
    @include vw($margin-left: 65, $width: 175);
    @include respond-to(xs) {
      // margin-left: 3vh;
      // width: 17vh;
      // margin-left: 7%;
      // width: 110px;
    }
  }
  &--xs {
    @include vw($font-size: 18);
    font-family: $sec-med-norm;
    color: $brand;
  }
  &-num {
    color: $grey;
    font-family: $sec-black;
    @include vw($font-size: 21, $margin-bottom: 6);
    @include respond-to(xs) {
      font-size: 18.7px;
    }
  }
  &-bg {
    position: relative;
    @include vw($margin-top: 16);
    @include respond-to(xs) {
      margin-bottom: 5px;
    }
    span {
      &:first-child {
        @include vw($font-size: 18);
        color: $grey;
        font-family: $sec-med-norm;
        display: block;
        position: absolute;
        bottom: 0;
        @include respond-to(xs) {
          font-size: 18px;
          bottom: -5px;
        }
      }
      &:nth-child(2) {
        @include vw($font-size: 40);
        color: $brand-light;
        font-family: $sec-black;
        display: block;
        @include respond-to(xs) {
          font-size: 26px;
        }
      }
    }
    &.light {
      span {
        &:nth-child(2) {
          color: rgb(237, 237, 237);
        }
      }
    }
  }
  &--md {
    @include vw($font-size: 21, $margin-top: 23, $margin-bottom: 3);
    font-family: $sec-black;

    @include respond-to(xs) {
      font-size: 19px;
    }
  }
}
.subtitle {
  @include vw($margin-top: -41, $font-size: 32);
  font-family: $sec-bold;
  color: $grey;
}
.text-md {
  color: $brand;
  font-family: $sec-med;
  @include vw($font-size: 18, $margin: 20 auto);
  @include respond-to(xs) {
    // font-size: 2vh;
    // margin: 1vh 0;
  }
}
.book {
  @include vw(
    // $width: 1190,
      // $height: 842,
      $width: 595,
    $height: 842,
    // $border: 1 solid $light,
      // $margin: 30 auto
      $margin: 0 auto
  );

  box-shadow: 0 4px 10px #666;
  @include respond-to(xs) {
    // width: 100vw;
    // height: 100vh;
    // margin: 0 auto 0;
    width: 100%;
    height: auto;
  }
}
.shadow {
  box-shadow: 0 4px 10px #666;
}
.page {
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  // @include vw($width: 595, $height: 842, $margin-bottom: 30);
  @include vw($width: 595, $height: auto, $margin-bottom: 0);
  perspective: 2000px;
  position: relative;
  overflow: hidden;
  @include respond-to(xs) {
    width: 100vw;
    margin-bottom: 0;
    height: auto;
    box-shadow: none;
  }
  &--blue {
    background: $secondary;
    .page__title {
      opacity: 0.7;
    }
    .title-bg {
      span:last-child {
        color: #9faeb7;
      }
      span:first-child {
        color: #8194a6;
      }
    }
  }
  &--bg {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 85%, 0% 100%);
      background: $brand-light;
      @include vw($height: 302);
      @include respond-to(xs) {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%, 0 100%);
      }
    }
  }
  &--square {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 50%;
      @include vw($width: 173, $height: 330);
      background: $brand-light;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    height: 100%;
    @include vw($padding: 35 0);
    @include respond-to(xs) {
      // padding: 3vh 0;
    }
  }
  &__title {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 0;
    &-header {
      @include vw($font-size: 300);
      font-family: $sec-bold;
      text-transform: uppercase;
      //  color: rgba(255,255,255,.26);
      opacity: 0.26;
      line-height: 1;
      position: absolute;
      // left: 50%;
      transform: translate(-3%, -15%);
      @include respond-to(xs) {
        // font-size: 28vh;
        // font-size: 190px;
        left: 50%;
        transform: translate(-50%, -15%);
      }
      &--white {
        color: #fff;
      }
      &--grey {
        color: $secondary-dark;
      }
      &--light {
        color: #c7c9cb;
      }
      &--brand {
        color: #436a46;
      }
    }
    &-footer {
      @include vw($font-size: 300);
      font-family: $sec-bold;
      text-transform: uppercase;
      line-height: 1;
      opacity: 0.26;
      position: absolute;
      // left: 50%;
      bottom: 0;
      transform: translate(-3%, 20%);
      @include respond-to(xs) {
        // font-size: 28vh;
        // font-size: 190px;
        left: 50%;
        transform: translate(-50%, 20%);
      }
      &--white {
        color: #fff;
      }
      &--grey {
        color: $secondary-dark;
      }
      &--light {
        color: #c7c9cb;
      }
    }
  }
}

.content-table {
  width: 86%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  @include respond-to(xs) {
    padding-bottom: 90px;
  }
  &__title {
    @include vw($font-size: 24);
    font-family: $sec-black;
    @include respond-to(xs) {
      // font-size: 3vh;
      // font-family: $sec-bold;
    }
  }
  &__page {
    @include vw($font-size: 48, $right: 25);
    line-height: 1;
    position: absolute;
    // right: 0;
    @include respond-to(xs) {
      // font-size: 4vh;
      // right: 2vh;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
    font-family: $sec-black;
    align-items: center;
    text-decoration: none;
    color: #fff;
    position: relative;
    background: $text;
    @include vw(
      $padding-left: 30,
      $padding-right: 20,
      $margin-bottom: 10,
      $min-height: 40
    );
    @include respond-to(xs) {
      // padding-left: 3vh;
      // padding-right: 8vh;
      // margin-bottom: 1vh;
      // min-height: 4.3vh;
    }
    &--extra-margin {
      @include vw($margin-bottom: 25);
      @include respond-to(xs) {
        // margin-bottom: 2vh;
      }
    }
    &--md {
      flex-basis: 94%;
      background: $brand-dark;
      @include vw($padding-left: 20);
      @include respond-to(xs) {
        // padding-left: 2vh;
      }
      .content-table__title {
        @include vw($font-size: 18);
        font-family: $sec-bold;
        @include respond-to(xs) {
          // font-size: 2.5vh;
        }
      }
    }
    &--reg {
      flex-basis: 88%;
      background: $brand;
      @include vw($padding-left: 20);
      @include respond-to(xs) {
        // padding-left: 2vh;
      }
      .content-table__title {
        @include vw($font-size: 18);
        font-family: $sec-bold;
        @include respond-to(xs) {
          // font-size: 2.5vh;
        }
      }
      .content-table__page {
        @include vw($font-size: 42);
        font-family: $sec-bold;
        @include respond-to(xs) {
          // font-size: 3.7vh;
        }
      }
    }
    &--sm {
      flex-basis: 82%;
      background: $secondary-dark;
      @include vw($padding-left: 20);
      @include respond-to(xs) {
        // padding-left: 2vh;
      }
      .content-table__title {
        @include vw($font-size: 18);
        font-family: $sec-bold;
        @include respond-to(xs) {
          // font-size: 2.5vh;
          //  font-size: 12px;
        }
      }
      .content-table__page {
        @include vw($font-size: 34);
        font-family: $sec-bold;
        color: $grey;
        @include respond-to(xs) {
          // font-size: 3.5vh;
        }
      }
    }
    &--xs {
      flex-basis: 77%;
      background: $secondary;
      color: $grey;
      @include vw($padding-left: 20);
      @include respond-to(xs) {
        // padding-left: 2vh;
      }
      .content-table__title {
        @include vw($font-size: 18);
        font-family: $sec-med;
        @include respond-to(xs) {
          // font-size: 2.5vh;
        }
      }
      .content-table__page {
        @include vw($font-size: 24);
        font-family: $sec-bold;
        color: $grey;
        @include respond-to(xs) {
          // font-size: 3vh;
        }
      }
    }
  }
}
.page-full {
  position: relative;
  @include vw($height: 842);
  @include respond-to(xs) {
    height: 100vh;
  }
  .page__title-footer,
  .page__title-header {
  }
  .content-width {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &--daley {
    background: url('/images/daley.png') center center no-repeat;
    background-size: cover !important;
    .content-width {
      justify-content: space-between;
    }
    &:after {
      content: '';
      @include vw($height: 233);
      position: absolute;
      display: block;
      bottom: 0;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(12, 13, 13, 1) 100%
      );
      @include respond-to(xs) {
        height: 26vh;
      }
    }
  }
  &--ashley {
    justify-content: space-between;
    background: url('/images/ashley.png') center center no-repeat;
    background-size: cover !important;
    @include respond-to(xs) {
      background-position-x: 40%;
    }
    .content-width {
      justify-content: space-between;
    }
    &:after {
      content: '';
      @include vw($height: 233);
      position: absolute;
      display: block;
      bottom: 0;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(12, 13, 13, 1) 100%
      );
      @include respond-to(xs) {
        height: 26vh;
      }
    }
  }
  &--calor {
    justify-content: space-between;
    background: url('/images/bg-1.png') center center no-repeat;
    background-size: cover !important;
    .content-width {
      justify-content: center;
    }
    &:after {
      content: '';
      @include vw($height: 233);
      position: absolute;
      display: block;
      bottom: 0;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(12, 13, 13, 1) 100%
      );
      @include respond-to(xs) {
        height: 26vh;
      }
    }
  }
  &--exer {
    justify-content: space-between;
    background: url('/images/bg-1.png') center center no-repeat;
    background-size: cover !important;
    .content-width {
      justify-content: center;
    }
    &:after {
      content: '';
      @include vw($height: 233);
      position: absolute;
      display: block;
      bottom: 0;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(12, 13, 13, 1) 100%
      );
      @include respond-to(xs) {
        height: 26vh;
      }
    }
  }
  &--macr {
    background: $brand url(/images/macr.png);
    background-position: bottom center;
    background-size: 86%;
    background-repeat: no-repeat;
  }
  //   &__content-center{
  // display: flex;
  // height: 100%;
  // width: 100%;
  // flex-direction: column;
  // justify-content: center;
  // // align-items: center;
  // flex-wrap: wrap;
  //   }
  .quote--xl {
    .quote__sign {
      @include vw($font-size: 119);
      @include respond-to(xs) {
        // font-size: 17vh;
      }
    }

    .quote__text {
      @include vw($font-size: 54);
      text-align: center;
      line-height: 1;
      @include respond-to(xs) {
        // font-size: 6vh;
      }
    }
    .quote__author {
      @include vw($font-size: 18, $margin-top: 20);
      font-family: $sec-bold;
      color: #fff;
      display: block;
      text-align: center;
    }
    .quote__descr {
      font-family: $sec;
      @include vw($font-size: 16);
      color: $secondary;
      display: block;
      text-align: center;
    }
  }
  .quote__text {
    @include respond-to(xs) {
      // font-size: 3vh;
    }
  }
}

.quote {
  position: relative;
  @include vw($margin-bottom: 14);
  @include respond-to(xs) {
    // margin-bottom: 4vh;
  }
  &__sign {
    @include vw($font-size: 118);
    color: $secondary;
    font-family: $sec-black;
    position: absolute;
    transform: translateY(-56%);

    @include respond-to(xs) {
      // font-size: 16vh;
    }
    &--lg {
      background: url('/images/quote.png') center center no-repeat;
      background-size: contain;
      @include vw($width: 204, $height: 150);
      transform: translateY(5%);
      z-index: -1;
      @include respond-to(xs) {
        // width: 19vh;
        // height: 14vh;
        // width: 160px;
        // height: 120px;
      }
    }
  }
  &__text {
    color: #fff;
    font-family: $sec-black-it;
    @include vw($font-size: 18, $margin-bottom: 0);
    line-height: 1.4;
    @include respond-to(xs) {
      // font-size: 3vh;
      font-size: 16px;
    }
  }
}
.about {
  @include vw($font-size: 12);
  @include respond-to(xs) {
    font-size: 14px;
  }
  &__name {
    color: $brand;
    @include vw($margin-bottom: 0);
    font-family: $sec-bold;
    @include respond-to(xs) {
      // font-size: 14px;
      // margin-bottom: 7px;
    }
  }
  &__text {
    color: $secondary;
    font-family: $sec;
    line-height: 1.2;
    @include vw($margin-bottom: 0);
    @include respond-to(xs) {
      // margin-bottom: 5px;
    }
  }
}

.video {
  @include vw($width: 465, $margin: 0 auto 30);
  position: relative;
  max-width: 100%;
  @include respond-to(xs) {
    width: 80%;
    // margin-bottom: 5vh;
    width: 100%;
  }
  img {
    display: block;
    width: 100%;
  }
  &__content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  &__title {
    @include vw($font-size: 21, $margin-bottom: 10);
    font-family: $sec-bold;
    color: #fff;
    flex-basis: 100%;
    margin-top: auto;
    text-align: center;
    @include respond-to(xs) {
      // font-size: 2.7vh;
      // font-size: 21px;
    }
  }
  &-btn {
    @include vw(
      $width: 76,
      $height: 76,
      $font-size: 40,
      $border: 7 solid #fff,
      $padding-left: 6
    );
    margin-bottom: auto;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    transition: all 0.6s ease;
    transform: scale(0.8);
    transform: scale(1);
    @include respond-to(xs) {
      // width: 5vh;
      // height: 5vh;
      // font-size: 2vh;
      // border: 0.41vh solid #fff;
      // padding-left: 8;
      // border: 4px solid #fff;
      // font-size: 36px;
      // width: 70px;
      // height: 70px;
    }
    i {
      color: #fff;
    }
  }
  &-descr {
    @include vw($width: 465, $margin: 0 auto 0);
    max-width: 100%;
    @include respond-to(xs) {
      // width: 80%;
      width: 90%;
    }
    &__title {
      color: $grey;
      @include vw($font-size: 18);
      font-family: $sec-med-norm;
      @include respond-to(xs) {
        // font-size: 2.3vh;
      }
    }
    &__text {
      color: #fff;
    }
    &__links {
      color: $grey;
      @include vw($font-size: 18);
      font-family: $sec-med-norm;
      text-align: center;
      @include respond-to(xs) {
        // font-size: 2.3vh;
      }
    }
  }
}

.calories {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__item {
    flex-basis: 48%;
    @include vw($margin-bottom: 20);
    @include respond-to(xs) {
      flex-basis: 100%;
    }
  }
  &__title {
    @include vw($font-size: 14, $margin-bottom: 0);
    font-family: $sec-bold;
    display: flex;
    justify-content: space-between;
  }
}
.phones {
  display: flex;
  justify-content: space-between;
  @include vw($margin-top: 20);
  @include respond-to(xs) {
    flex-wrap: wrap;
  }
}
.phone {
  flex-basis: 49%;
  background: url(/images/phone-blue.svg) top center no-repeat;
  background-size: contain !important;
  text-align: center;
  @include vw($padding-left: 96);
  @include respond-to(xs) {
    max-width: 370px;
    margin-right: auto;
    margin-left: auto;
    flex-basis: 100%;
    background-size: cover !important;
    padding-left: 121.2px;
    margin-bottom: 30px;
  }
  &--green {
    background: url(/images/phone-green.svg) top center no-repeat;
    background-size: contain !important;
    .phone__section {
      &:first-child {
        @include vw($font-size: 14, $padding-top: 25, $padding-bottom: 17);
        font-family: $sec-bold;
        .phone__title {
          color: $brand-dark;
          @include vw($margin-bottom: 0);
          line-height: 1.2;
        }
        @include respond-to(xs) {
          font-size: 16px;
          padding-top: 34px;
          padding-bottom: 25px;
        }
      }
      &:nth-child(2) {
        @include vw($font-size: 16, $padding-top: 13, $padding-bottom: 17);
        font-family: $sec-bold;
        .phone__title {
          color: #131516;
          @include vw($margin-bottom: 0);
          line-height: 1.2;
        }
        @include respond-to(xs) {
          font-size: 18px;
          padding-top: 25px;
          padding-bottom: 27px;
        }
      }
      &:nth-child(3) {
        @include vw($font-size: 14, $padding-top: 28, $padding-bottom: 28);
        font-family: $sec-bold;
        .phone__title {
          color: #131516;
          @include vw($margin-bottom: 0);
          line-height: 1.2;
        }
        @include respond-to(xs) {
          font-size: 16px;
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }
      &:nth-child(4) {
        @include vw($font-size: 14, $padding-top: 20, $padding-bottom: 26);
        font-family: $sec-bold;
        &:after {
          display: none;
        }
        .phone__title {
          color: $brand-dark;
          @include vw($margin-bottom: 0);
          line-height: 1.2;
        }
        .phone__text {
          color: $brand-dark;
          font-family: $sec;
          @include vw($margin: 0 auto 0, $font-size: 9);
          line-height: 1.2;
          width: 75%;
          @include respond-to(xs) {
            font-size: 12px;
          }
        }
        @include respond-to(xs) {
          font-size: 16px;
          padding-top: 35px;
          padding-bottom: 40px;
        }
      }
    }
  }
  &__section {
    // @include vw($padding-right: 21);
    position: relative;
    &:after {
      content: '';
      width: 70%;
      position: absolute;
      background: #1a3e1c;
      height: 1px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &:first-child {
      @include vw($font-size: 14, $padding-top: 25, $padding-bottom: 17);
      font-family: $sec-bold;
      .phone__title {
        color: #131516;
        @include vw($margin-bottom: 0);
        line-height: 1.2;
      }
      @include respond-to(xs) {
        font-size: 16px;
        padding-top: 34px;
        padding-bottom: 25px;
      }
    }
    &:nth-child(2) {
      @include vw($font-size: 16, $padding-top: 13, $padding-bottom: 17);
      font-family: $sec-bold;
      .phone__title {
        color: $brand;
        @include vw($margin-bottom: 0);
        line-height: 1.2;
      }
      @include respond-to(xs) {
        font-size: 18px;
        padding-top: 25px;
        padding-bottom: 27px;
      }
    }
    &:nth-child(3) {
      @include vw($font-size: 14, $padding-top: 28, $padding-bottom: 28);
      font-family: $sec-bold;
      .phone__title {
        color: $brand;
        @include vw($margin-bottom: 0);
        line-height: 1.2;
      }
      @include respond-to(xs) {
        font-size: 16px;
        padding-top: 40px;
        padding-bottom: 35px;
      }
    }
    &:nth-child(4) {
      @include vw($font-size: 14, $padding-top: 20, $padding-bottom: 20);
      font-family: $sec-bold;
      &:after {
        display: none;
      }
      @include respond-to(xs) {
        font-size: 16px;
        padding-top: 25px;
        padding-bottom: 29px;
      }
      .phone__title {
        color: #131516;
        @include vw($margin-bottom: 0);
        line-height: 1.2;
      }
      .phone__text {
        color: #131516;
        font-family: $sec;
        @include vw($margin: 0 auto 0, $font-size: 9);
        line-height: 1.2;
        width: 75%;
        @include respond-to(xs) {
          font-size: 12px;
        }
      }
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include vw($margin-top: 15);
  &__item {
    flex-basis: 49%;
    position: relative;
    @include vw($padding: 7 0 7 10, $margin-bottom: 13);
    @include respond-to(xs) {
      padding: 5px 0;
      margin-bottom: 10px;
      flex-basis: 100%;
    }
  }
  &__circle {
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    // border: 1px solid;
    box-shadow: 0 0 9px -1px rgba(0, 0, 0, 0.2);
    position: absolute;
    position: absolute;
    @include vw($width: 53, $height: 53, $top: 0, $padding: 5);
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-to(xs) {
      width: 57.1px;
      height: 57.1px;
      top: 0;
      padding: 3.5px;
    }
    img {
      width: 100%;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    @include vw(
      $margin-left: 30,
      $min-height: 40,
      $padding-left: 31,
      $border: 4 solid $brand-light
    );
    @include respond-to(xs) {
      margin-left: 21px;
      min-height: 48px;
      padding-left: 51px;
      border: 3px solid rgba(93, 149, 98, 0.16);
    }
  }
  &__check {
    display: block;
    @include vw($width: 16, $margin-right: 10);
    @include respond-to(xs) {
      width: 21.2px;
      margin-right: 7px;
    }
  }
  &__text {
    font-family: $sec-bold;
    @include vw($font-size: 14);
    @include respond-to(xs) {
      font-size: 16px;
    }
    small {
      @include vw($font-size: 9);
      font-family: $ff-light;
      @include respond-to(xs) {
        font-size: 9px;
      }
    }
  }
}
.card-border {
  @include vw($padding: 10 20, $border: 4 solid $brand-light);
  text-align: center;
  @include respond-to(xs) {
    border: 3px solid $brand-light;
  }
  &__title {
    @include vw($font-size: 18);
    font-family: $sec-bold;
    @include respond-to(xs) {
      font-size: 18px;
    }
  }
  &__text {
    @include vw($font-size: 16.5);
    font-family: $sec-it;
    @include respond-to(xs) {
      font-size: 16px;
    }
  }
}
.page-cover {
  background: url(/images/cover.jpg) center center no-repeat;
  background-size: cover;
  @include vw($height: 842);
  @include respond-to(xs) {
    height: 100vh;
  }
  &__header {
    color: #fff;
    text-align: center;
    @include vw($margin-top: 125);
    @include respond-to(xs) {
      margin-top: 24vh;
    }
  }
  &__suptitle {
    display: block;
    font-family: $ff-light;
    @include vw($font-size: 32, $letter-spacing: 6);
    @include respond-to(xs) {
      // font-size: 19px;
      // letter-spacing: 5px;
    }
  }
  &__title {
    display: block;
    font-family: $ff-bold;
    @include vw($font-size: 110, $margin-top: -25);
    @include respond-to(xs) {
      // font-size: 64px;
      // margin-top: -9px;
    }
  }
  &__subtitle {
    display: block;
    font-family: $ff;
    @include vw($font-size: 24, $margin-top: -10);
    @include respond-to(xs) {
      // font-size: 16px;
      // margin-top: -4px;
    }
  }
}
.page-15 {
  .title {
    @include vw($margin-top: 280, $margin-bottom: 0);
  }
}
.page-16 {
  background: #aec1d3 url(/images/img-1.png) center center no-repeat;
  background-size: contain;
  background-position-y: 63%;
  &__img {
    display: none;
  }
}

.page-19 {
  &__title {
    @include vw($font-size: 18);
    font-family: $sec-bold;
    color: #e7e2e1;
    @include respond-to(xs) {
      font-size: 16px;
    }
  }
  .phone {
    &:first-child {
      .phone__section {
        &:first-child {
          @include vw($padding-top: 15, $padding-bottom: 11);
          @include respond-to(xs) {
            padding-top: 23px;
            padding-bottom: 17px;
          }
        }
      }
    }
    &:nth-child(2) {
      .phone__section {
        &:nth-child(4) {
          @include respond-to(xs) {
            font-size: 16px;
            padding-top: 32px;
            padding-bottom: 18px;
          }
        }
      }
    }
  }
}

.page-20 {
  &__list {
    li {
      @include vw($margin-bottom: 0);
      line-height: 1.68;
      span {
        font-family: $sec-med-norm;
      }
    }
  }
  &__note {
    color: $brand;
    font-family: $sec-med;
    @include vw($font-size: 15, $margin-top: 0);
    line-height: 1;
    @include respond-to(xs) {
      font-size: 14px;
      line-height: 1.2;
    }
    a {
      @include vw($font-size: 11);
      color: $brand;
      text-decoration: none;
      @include respond-to(xs) {
        font-size: 12px;
      }
    }
  }
  .card-border {
    position: relative;
    @include vw($border: none);
    @include vw($margin-top: 40);
    z-index: 3;
    @include respond-to(xs) {
      border: none;
    }
    &__title {
      color: $brand;
    }
  }
  &__img {
    @include vw($margin-left: -33, $margin-right: -33, $margin-top: -70);
    position: relative;
    z-index: 0;
  }
}
.page-21 {
  .card-border {
    @include vw($margin-top: 20);
  }
  &__lists {
    display: flex;
    flex-wrap: wrap;
  }
  .list {
    @include vw($margin-top: 25);
    flex-basis: 49%;
    @include respond-to(xs) {
      flex-basis: 100%;
    }
    &__title {
      text-align: center;
      color: $brand;
      font-family: $sec-bold;
      @include vw($font-size: 18, $margin-bottom: 25);
      width: 100%;
      @include respond-to(xs) {
        font-size: 18px;
      }
    }
    &__item {
      flex-basis: 100%;
    }
  }
}
.page-23 {
  &__lg {
    @include vw($margin-top: 30);
  }
  &__bold {
    font-family: $sec-bold;
    color: #fff;
  }
  &__imgs {
    display: flex;
    @include vw($margin-top: 40);
    @include respond-to(xs) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__img {
    @include vw($width: 92, $height: 92, $padding: 10, $margin: 0 auto);
    background: #fff;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-to(xs) {
      width: 90px;
      height: 90px;
      padding: 10px;
    }
    img {
      width: 100%;
    }
    &-item {
      flex-basis: 20%;
      flex-shrink: 0;
      flex-grow: 0;
      text-align: center;
      &:nth-child(even) {
        @include vw($margin-top: 80);
        @include respond-to(xs) {
          margin-top: 0;
        }
      }
      @include respond-to(xs) {
        flex-basis: 50%;
        margin-bottom: 20px;
      }
    }
    &-title {
      font-family: $sec-bold;
      @include vw($font-size: 18);
      color: $grey;
      @include respond-to(xs) {
        font-size: 16px;
      }
    }
  }
}

.page-24 {
  &__text {
    .big-text {
      @include vw($font-size: 18);
      @include respond-to(xs) {
        font-size: 18px;
      }
    }
  }
  &__img {
    @include vw($margin-top: 60);
    img {
      width: 76%;
      display: block;
      margin: 0 auto;
      @include respond-to(xs) {
        width: 98%;
      }
    }
    &.sec {
      @include vw($margin-top: 44);
    }
  }
}

.page-25 {
  &__bold {
    @include vw($font-size: 18);
    font-family: $sec-black;
    line-height: 1.2;
    @include respond-to(xs) {
      font-size: 18px;
    }
  }
  &__list-title {
    color: $brand;
    @include vw($font-size: 18, $margin-bottom: 0);
    font-family: $sec-med-norm;
    @include respond-to(xs) {
      font-size: 18px;
    }
  }
  &__list-item {
    font-family: $sec;
    list-style-type: circle;
    list-style-position: inside;
    @include vw($margin-left: 30, $margin-bottom: 14);
  }
}
.page-26 {
  background: url(/images/bg-6.png) center center no-repeat;
  background-size: cover;
}
.page-28 {
  .list {
    width: 96%;
    @include vw($margin: 38 auto 12);
    &__item {
      flex-basis: 42%;
      @include respond-to(xs) {
        flex-basis: 100%;
      }
      &:nth-child(2) {
        flex-basis: 52%;
        @include respond-to(xs) {
          display: none;
        }
      }
    }
    &__circle {
      background: green;
      width: 60px;
      height: 60px;
      @include vw($width: 60, $height: 60);
      @include respond-to(xs) {
        width: 60px;
        height: 60px;
      }
    }
    &__content {
      padding-left: 60px;
      @include vw($padding-left: 60, $border: 4 solid #fff);
      @include respond-to(xs) {
        border: 4px solid $brand-light;
      }
    }
    &__text {
      font-size: 16px;
      @include vw($font-size: 16);
      @include respond-to(xs) {
        font-size: 16px;
      }
    }
  }
  .func {
    width: 89%;
    margin: 0 auto;
    @include respond-to(xs) {
      width: 98%;
    }
    &__title {
      @include vw($font-size: 16, $padding-left: 30, $padding-bottom: 7);
      font-family: $sec-bold;
      @include respond-to(xs) {
        padding-left: 20px;
        font-size: 16px;
      }
    }
    &__text {
      @include vw($font-size: 11);
      line-height: 1.2;
      // font-family: $sec-bold;
      @include respond-to(xs) {
        padding-left: 20px;
        font-size: 12px;
        margin-bottom: 0px;
      }
    }
    &__border {
      background: url(/images/border.png) center center no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      width: 100%;
      @include vw($height: 31);
      @include respond-to(xs) {
        display: none;
      }
    }
    &__row {
      display: flex;
      @include vw($margin-bottom: 16);
      @include respond-to(xs) {
        flex-wrap: wrap;
      }
    }
    &__right {
      @include vw($padding-left: 7);
      display: flex;
      align-items: center;
      @include respond-to(xs) {
        flex-basis: 100%;
      }
    }
    &__left {
      position: relative;
      flex-basis: 55.3%;
      flex-shrink: 0;
      @include respond-to(xs) {
        flex-basis: 100%;
      }
    }
  }
}
.page-40 {
  background: url(/images/bg-7.png) center center no-repeat;
  background-size: cover;
}
.page-27 {
  .subtitle {
    color: $secondary-dark;
    @include vw($margin-bottom: 15);
  }
  &__title {
    font-family: $sec-black;
    @include vw($font-size: 32, $margin: 26 0 0);
    line-height: 1;
    color: $secondary-dark;
  }
}
.page-29 {
  .subtitle {
    color: $secondary-dark;
    @include vw($margin-bottom: 15);

    // color: rgb(159, 174,183);
  }
  &__title {
    font-family: $sec-black;
    @include vw($font-size: 32, $margin: 26 0 0);
    line-height: 1;
    // color: rgb(159, 174,183);
    color: $secondary-dark;
  }
  .title-bg {
    span:first-child {
      color: $secondary-dark;
      color: #8194a6;
    }
    span:last-child {
      // color: rgb(145, 168, 187);

      color: rgb(159, 174, 183);
    }
  }
}

.page-30 {
  &__row1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include vw($margin-top: 40);
    .page-30__item {
      &:nth-child(2) {
        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
  &__row2 {
    display: flex;
    width: 100%;
    justify-content: center;
    @include vw($margin-top: 20);
    .page-30__item {
      @include vw($margin: 0 25);
    }
  }
  &__row3 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include vw($margin-top: 20);
    .page-30__item {
      @include vw($margin: 0 0);
    }
    .page-30__title {
      @include vw($min-height: 44);
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .page-30__img {
      @include vw($width: 129, $height: 129, $padding: 10);
      box-shadow: 0 0 9px -1px rgba(0, 0, 0, 0.2);
      background: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
      }
    }
  }
  &__title {
    color: $grey;
    font-family: $sec-black;
    text-align: center;
    @include vw($font-size: 18, $margin-bottom: 9);
  }
  &__list {
    position: absolute;
    transform: translateX(100%);
    @include vw($right: -30, $top: 70);
    li {
      color: $brand;
      list-style-type: circle;
      @include vw($font-size: 19, $margin-bottom: 2);
      span {
        color: $text;
      }
    }
  }
  &__item {
    position: relative;
  }
  &__img {
    @include vw($width: 159, $height: 159, $padding: 16);
    box-shadow: 0 0 9px -1px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      //       max-height: 100%;
      //       max-width: 100%;
      // width: auto;
      // object-fit: cover;
    }
  }
}

.page-33 {
  .video {
    width: 100%;
  }
  .video-btn {
    @include vw($margin: auto);
  }
  .video-descr {
    @include vw($margin-top: 80);
  }
  .title {
    @include vw($font-size: 82, $margin-top: 42);
  }
  .quote {
    font-family: $sec-bold-it;
    color: #fff;
    @include vw($font-size: 32);
    text-align: center;
    &__sign {
      @include vw($width: 160, $left: 6, $top: -80);
      color: $secondary-dark;
      background: url(/images/quote-dark.png) center center no-repeat;
      background-size: contain;
    }
  }
}

.page-34 {
  @include vw($min-height: 730);
  &__sign {
    position: absolute;
    &-1 {
      @include vw($width: 156, $right: 60, $top: 530);
    }
    &-2 {
      @include vw($left: 0, $top: 350, $width: 196);
    }
    &-3 {
      @include vw($right: 15, $top: 38, $width: 138);
    }
  }
}
.page-35 {
  ul {
    @include vw($margin-top: 15);
    width: 80%;
    @include respond-to(xs) {
      width: 88%;
    }
    li {
      @include vw($padding: 6 0, $font-size: 16, $border-top: 1 solid #e0e1e3);
      font-family: $sec-med-norm;
      @include respond-to(xs) {
        font-size: 16px;
        padding: 6px 0;
      }
      &:last-child {
        @include vw($border-bottom: 1 solid #e0e1e3);
      }
    }
  }
}

.page-37 {
  .list {
    justify-content: flex-start;
  }
  .list__item {
    @include vw(
      $margin-bottom: 15,
      // $margin-right: 18,
        $padding-left: 0
    );
    margin-right: 2.5%;
    @include respond-to(xs) {
      margin-right: 0;
    }
    .list__circle {
      @include vw($width: 48, $height: 48);
      @include respond-to(xs) {
        width: 40px;
        height: 40px;
      }
    }
    .list__text {
      @include vw($font-size: 16);
      font-family: $sec-black;
      @include respond-to(xs) {
        font-size: 13.5px;
      }
      small {
        @include vw($font-size: 9);
        @include respond-to(xs) {
          font-size: 11px;
        }
      }
    }
    .list__content {
      @include vw($min-height: 34, $padding-left: 20);
      @include respond-to(xs) {
        min-height: 34px;
        padding-left: 24.5px;
      }
    }
    &:first-child {
      flex-basis: 59%;
      margin-right: 30%;
      @include vw($margin-bottom: 20);
      @include respond-to(xs) {
        margin-right: 0;
        flex-basis: 100%;
      }
      .list__text {
        @include vw($font-size: 16.7);
        @include respond-to(xs) {
          font-size: 16px;
        }
      }
      .list__content {
        @include vw($min-height: 43, $padding-left: 35);
      }
      .list__circle {
        @include vw($width: 60, $height: 60);
        background: $brand;
      }
    }
    &:nth-child(2) {
      flex-basis: 33%;
      @include respond-to(xs) {
        font-size: 16px;
        flex-basis: 100%;
      }
      .list__circle {
        img {
          width: 53%;
          // @include vw($);
        }
      }
    }
    &:nth-child(3) {
      flex-basis: 46%;
      @include respond-to(xs) {
        flex-basis: 100%;
      }
      .list__circle {
        img {
          width: 63%;
          // @include vw($);
        }
      }
    }
    &:nth-child(4) {
      flex-basis: 47%;
      @include respond-to(xs) {
        flex-basis: 100%;
      }
      .list__circle {
        img {
          width: 60%;
        }
      }
    }
    &:nth-child(5) {
      flex-basis: 47%;
      @include respond-to(xs) {
        flex-basis: 100%;
      }
      .list__circle {
        img {
          width: 77%;
        }
      }
    }
    &:nth-child(6) {
      flex-basis: 100%;
      @include vw($margin-right: 0);
      .list__circle {
        @include respond-to(xs) {
          top: 5px;
        }
        img {
          width: 77%;
        }
      }
      .list__text {
        @include vw($font-size: 15);
        font-family: $sec-black;
        @include respond-to(xs) {
          font-size: 13.5px;
        }
      }
    }
  }
  .list-full {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include vw($margin-right: -33, $margin-top: 41, $margin-bottom: 60);
    &__circle {
      position: absolute;
      left: 0;
      top: 0;
      background: $brand;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $sec-black;
      color: #fff;
      @include vw($font-size: 21, $width: 82, $height: 82);
      box-shadow: 0 0 9px -1px rgba(0, 0, 0, 0.2);
    }
    &__content {
      box-shadow: 0 0 5px -2px #131516;
      background: $brand-dark;
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
      @include vw($padding-left: 106);
      @include respond-to(xs) {
        padding-left: 54.2px;
        padding-right: 10px;
      }
    }
    &__text {
      color: #fff;
      @include vw($font-size: 16);
      font-family: $sec-black;
      @include respond-to(xs) {
        font-size: 13.5px;
      }
    }
    &__item {
      position: relative;
      @include vw($padding-left: 50, $padding-top: 6, $height: 76);
      // height: 100%;
      display: flex;
      align-items: center;
      width: 100%;

      &:nth-child(2) {
        width: 94%;
        @include vw($margin-top: -11);
        .list-full__content {
          background: $brand;
        }
        .list-full__circle {
          z-index: 9999999999;
          background: $brand-dark;
          @include vw($height: 98, $width: 98, $top: -3);
        }
      }
      &:nth-child(3) {
        width: 84%;
        @include vw($margin-top: -6);
        .list-full__circle {
          @include vw($width: 116, $height: 116, $top: -5);
          z-index: 999;
        }
      }
    }
  }
}

.page-38 {
  position: relative;
  &__info {
    color: $secondary;
    @include vw($font-size: 18);
    font-family: $sec-med;
    @include respond-to(xs) {
      font-size: 18px;
    }
  }
  &__graph {
    width: 100%;
    @include vw($max-width: 290, $margin: 30 auto 37);
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 50%;
    @include vw($width: 173, $height: 330);
    background: $brand-light;
  }
}

.page-39 {
  .quote {
    @include vw($padding-top: 63);
    &__sign {
      @include vw($top: 14);
    }
    &__title {
      @include vw($font-size: 18, $margin-bottom: 0);
      font-family: $sec-med-norm;
      @include respond-to(xs) {
        font-size: 16px;
      }
    }
    &__text {
      color: $brand;
      @include vw($font-size: 21);
      font-family: $sec-med;
      text-align: center;
      @include respond-to(xs) {
        font-size: 18px;
      }
    }
    &__footer {
      @include vw($font-size: 21);
      font-family: $sec-bold-it;
      text-align: center;
      @include respond-to(xs) {
        font-size: 18px;
      }
    }
  }
}
.page-41 {
  .card-border {
    width: 93%;

    @include vw($margin: 46 auto 24, $padding: 30 25 20);
    &__text {
      @include vw($font-size: 21);
      color: $brand;
      line-height: 1.4;
      font-family: $sec-black-it;
    }
  }
}

.page-42 {
  .calories {
    margin: auto;
    @include vw($width: 410, $margin: 58 auto);
    max-width: 100%;
    &__item {
      flex-basis: 100%;
      @include vw($margin-bottom: 14);
    }
    &__title {
      span {
        &:first-child {
          @include vw($padding-left: 15);
        }
        &:last-child {
          flex-basis: 50%;
          @include vw($padding-left: 40);
        }
      }
    }
  }
}
.charts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  .pie {
    &-wrapper {
      position: relative;
      @include vw($width: 51, $height: 51, $margin: 0 16);
      @include respond-to(xs) {
        width: 71px;
        height: 71px;
      }
      &:nth-child(3) {
        .pie__left {
          transform-origin: center;
          transform: rotateZ(90deg) translate(-51%, -25%);
        }
        .pie__right {
          transform-origin: center;
          transform: rotateZ(90deg) translate(-51%, -25%);
        }
      }
    }
    &__progress {
      height: 100%;
      width: 100%;
      // clip: rect(0, 1em, 1em, 0.5em);
      left: 0;
      position: absolute;
      top: 0;
      // clip: rect(auto, auto, auto, auto);
    }

    &__left,
    &__right {
      width: 50%;
      overflow: hidden;
      position: absolute;
      height: 100%;
      &:before {
        content: '';
        @include vw($width: 51, $height: 51, $border: 6 solid #fff);

        border-radius: 50%;
        // clip: rect(0, 0.5em, 1em, 0);
        left: 0;
        position: absolute;
        top: 0;
        display: block;
        box-sizing: border-box;
        @include respond-to(xs) {
          width: 71px;
          height: 71px;
          border: 6px solid #fff;
        }
      }
    }
    &__left {
      transform-origin: center;
      transform: rotateZ(135deg) translate(-86%, -16%);
      @include respond-to(xs) {
        transform: rotateZ(135deg) translate(-88%, -17%);
      }
    }
    &__right {
      transform-origin: center;
      transform: rotateZ(97deg) translate(-60%, -24%);
      @include respond-to(xs) {
        transform: rotateZ(97deg) translate(-58%, -25%);
      }
    }
    &__label {
      position: relative;
      @include vw($font-size: 15);
      font-family: $sec-black;
      color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: default;
      position: absolute;
      text-align: center;
      @include respond-to(xs) {
        font-size: 16px;
      }
    }
    &__text {
      @include vw($font-size: 7.4);
      font-family: $sec-black;
      position: absolute;
      width: 100%;
      bottom: 0;
      transform: translateY(151%);
      text-align: center;
      line-height: 1.2;
      @include respond-to(xs) {
        font-size: 10px;
      }
    }
    &__shadow {
      height: 100%;
      width: 100%;
      border: 0.1em solid #bdc3c7;
      border-radius: 50%;
      @include vw($border: 6 solid $secondary-dark);
      @include respond-to(xs) {
        border: 6px solid $secondary-dark;
      }
    }
    &__footer {
      @include vw($font-size: 8.27, $margin-top: 20);
      font-family: $sec-med;
      flex-basis: 100%;
      text-align: center;
      @include respond-to(xs) {
        font-size: 10px;
        margin-top: 36px;
      }
    }
  }
}

.page-44 {
  background: url(/images/bg-4.png) center center no-repeat;
  background-size: cover;
  @include respond-to(xs) {
    background: url(/images/bg-4.png) center right no-repeat;
    background-size: cover;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    @include vw(
      $margin: 60 auto 113,
      $height: 65,
      $border-radius: 50,
      $border: 4 dashed #fff,
      $width: 233,
      $padding: 7
    );
    @include respond-to(xs) {
      width: 260px;
      height: 60px;
      padding: 7px;
    }
    .btn {
      background: $brand;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      @include vw($font-size: 14, $border-radius: 50);
      font-family: $sec-black;
      text-decoration: none;
      color: #fff;
      @include respond-to(xs) {
        font-size: 16px;
      }
    }
  }
  .calculate {
    @include vw($border: 4 solid #fff, $padding: 10);
    &__title {
      @include vw($font-size: 21, $margin-bottom: 35);
      color: $brand;
      font-family: $sec-black;
      @include respond-to(xs) {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
    &__block {
      @include vw($margin-bottom: 30);
      &-title {
        @include vw($font-size: 18);
        font-family: $sec-med-norm;
        color: $secondary;
        @include respond-to(xs) {
          font-size: 16px;
          line-height: 1.2;
        }
      }
      &-text {
        @include vw($font-size: 18);
        font-family: $sec-med-norm;
        color: #fff;
        @include respond-to(xs) {
          font-size: 16px;
          line-height: 1.4;
        }
      }
    }
  }
}

.page-45 {
  .title-bg {
    span {
      &:first-child {
        color: gb(152, 168, 177);
      }
      &::last-child {
      }
    }
  }
}
.page-50 {
  background: url(/images/bg-09.png) center center no-repeat;
  background-size: cover;
  &__header {
    color: #fff;
    text-align: center;
    @include vw($margin-top: 125);

    @include respond-to(xs) {
      margin-top: 24vh;
    }
  }
  &__suptitle {
    display: block;
    font-family: $ff-light;
    @include vw($font-size: 32, $letter-spacing: 6);
    @include respond-to(xs) {
      // font-size: 19px;
      // letter-spacing: 5px;
    }
  }
  &__title {
    display: block;
    font-family: $ff-bold;
    line-height: 0.9;
    @include vw($font-size: 110, $margin-top: -6);
    @include respond-to(xs) {
      // font-size: 64px;
      // margin-top: -9px;
    }
  }
  &__subtitle {
    display: block;
    font-family: $ff;
    @include vw($font-size: 24, $margin-top: -10);
    @include respond-to(xs) {
      // font-size: 16px;
      // margin-top: -4px;
    }
  }
}
.page-51 {
  .title {
    @include vw($margin-bottom: 8);
  }
  &__text {
    @include vw($margin-bottom: 63);
  }
}

.list-plus {
  &__title {
    @include vw($font-size: 21, $margin-bottom: 5);
    font-family: $sec-black;
    @include respond-to(xs) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  &__items {
    @include vw($margin-bottom: 25);
  }
  &__item {
    display: flex;
    align-items: center;
    @include vw(
      $padding-bottom: 10,
      $border-bottom: 1 solid #e0e1e3,
      $margin-bottom: 6
    );
    @include respond-to(xs) {
      padding-bottom: 10px;
      margin-bottom: 8px;
    }
  }
  &__sign {
    color: $brand;
    @include vw($border: 2 solid #e0e1e3, $margin-right: 10);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    @include vw($width: 17, $height: 17, $padding-right: 0.5);
    @include respond-to(xs) {
      width: 15px;
      height: 15px;
    }
  }
  &__text {
    color: $brand;
    @include vw($font-size: 14);
    @include respond-to(xs) {
      font-size: 16px;
    }
  }
}
.page-53 {
  background: url('/images/bg-08.png') center center no-repeat;
  background-size: cover;
  .quote {
    @include vw($margin-top: 332);
    &__sign {
      transform: translate(80%, -56%);
    }
  }
}
.page-54 {
  .title {
    @include vw($margin-bottom: 240);
  }
  .quote {
    @include vw($margin-bottom: 33);
    &__sign {
      transform: translate(0%, -22%);
    }
    &__title {
      @include vw($font-size: 34, $margin-bottom: 10);
      font-family: $sec-black;
      color: $brand;
      text-align: center;
    }
    &__text {
      font-family: $sec-it;
      color: $text;
    }
  }
  &__about {
    display: flex;
    .about {
      &:first-child {
        @include vw($padding-right: 56);
      }
    }
  }
}

// Keyframe animations
//////////////////////
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(30%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

// fixed side navigation with dots
.fixed-side-navbar {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99999;
  text-align: right;
  @include vw($padding: 30 0);
  transition: all 0.3s;
  transform: translateY(-50%);
  @include respond-to(xs){
    display: none;
  }
.nav{
  line-height: 0;
}
  .nav > li {
    &.active {
      a {
        background-color: transparent;
        color: #000;
        &:after {
          @include vw($width: 15);
          @include respond-to(xs){
            width: 20px;
          }
        }
        span{
          opacity: 1;
          // @include respond-to(xs){
          //   opacity: 0
          // }
        }
      }
    }
    a {
      color: #000;
      background: transparent;
      position: relative;
      display: inline-block;
      border-right: none;
      text-decoration: none;
      position: relative;

      @include vw(
          $font-size: 16,
            $padding-right: 25,
$min-height: 6
        );
        span {
        // link text
        // display: none;
        // background:  #fff;
        display: block;
        opacity: 0;
        transition: all 0.2s;
        @include vw($font-size: 8);
        line-height: 1;
        // width: 140px;
        position: absolute;
        // right: 40px;
        @include vw($width: 100,
        $right: 30);
        @include respond-to(xs){
          font-size: 12px;
        }

      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: $text;
        transition: all 0.3s;
        height: 1px;
        @include vw($width: 10,   $right: 5);
        @include respond-to(xs){
          width: 15px;
        }
      }
      &:hover {
        & > span {
          animation: fadeInRight .2s;
          color: #000;
          display: block;
          opacity: 1;
        }
      }
    }
  }
}

body {
  position: relative;
  // scroll-behavior: smooth;
}
