@import "system/variables";
/******  HELPERS  ******/
.color-brand {
    color: $brand !important;
  }

  .color-white {
    color: #fff;
  }
  .color-sec{
color: $secondary;
  }
  .color-green{
    color: #7ECDCA
  }
  .color-grey{
    color: $grey;
  }
  .color-text{
color: $text;
  }
  .text-center {
    text-align: center;
  }
  .black{
    font-family: $sec-black;
  }
  .bold{
font-family: $sec-bold;
  }
  .medium{
font-family: $sec-med-norm;
  }
.big-text{
  @include vw($font-size: 32);
  font-family: $sec-bold;
  line-height: 1;
  @include respond-to(xs){
    font-size: 26px;
  }

}
.text-sm{
  @include vw($font-size: 14);
  @include respond-to(xs){
    font-size: 14px;
  }
}
  .flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.w-100 {
    width: 100%;
}
