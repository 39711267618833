@import "system/variables";
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
menu, article, section, nav, footer, figure, var {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-weight:normal;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section{
    display:block;
}
ol, ul, menu{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
    content: none;
}

:focus{
    outline: 0;
}
a:link, a:visited, a:hover, a:focus, a:active {
    outline: 0;
}
ins{
    text-decoration: none;
}

del{
    text-decoration: line-through;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}
html{
    font-size: 100.01%;
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: $text;
}
body{
    position:relative;
    font-family: $sec;
    color: $text;
    @include vw($font-size: 16);
@include respond-to(xs){
    // font-size: 1.7vh;
    font-size: 16px;
}
}
p{
    @include vw($margin-bottom: 7);
    @include respond-to(xs){
        // margin-bottom: 0.5vh;
        margin-bottom: 10px;
    }
    line-height: 1.68;
}
b, strong{
    font-weight:bold;
}
a,input,button {
	outline: none;
}
a{
    background-color: transparent;
	cursor: pointer;
}
a:hover,
a:active,
a:focus{
    outline: 0;
}
var{
    font-style:normal;
}

small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

img {
    border: 0;
    width: 100%;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}
button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

legend {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
}
optgroup {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}